@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');
* {
    font-family: 'Poppins', Tahoma, Geneva, Verdana, sans-serif;
}
/* @supports (font-variation-settings: normal) {
  * { font-family: 'Inter var', Tahoma, Geneva, Verdana, sans-serif; }
} */

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 1em;
}

html,
body {
    background-color: #202020;
}

a:link {
    text-decoration: none !important;
}

ion-tab-bar {
    background-color: #202020;
    border: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 75px;
}
ion-tab-button {
    --color: #555;
    --color-selected: #fff;
    font-size: 32px;
    width: 100%;
    max-width: 180px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.2s;
    cursor: pointer;
}
ion-tab-button > ion-icon {
    font-size: 32px;
}
ion-tab-button:nth-child(2) {
    margin-right: 70px;
}
ion-tab-button:nth-child(3) {
    margin-left: 70px;
}
ion-tab-button:last-child > div {
    border-color: #555;
}
ion-tab-button:last-child.tab-selected > div {
    border-color: #fff;
}
ion-ripple-effect {
    opacity: 0 !important;
}
:root {
    --ripple-color: rgba(0, 0, 0, 0);
}

span[aria-busy='true'] {
    width: 100%;
}
